export enum ELandingRoutes {
  SignInfoCorporate = '/benefits-corporate',
  SignInfo = '/benefits',
  How = '/how',
  FAQ = '/faq',
  TOC = 'toc',
  PrivacyPolicy = 'privacy-policy',
  Philosophy = '/philosophy'
}

export enum EClientRoutes {
  Main = '',
  Deposit = 'deposit',
  CreateRequest = 'requests-create',
  PaymentInfoMobile = 'payment-view',
  PersonalInfoMobile = 'personal-view',
  Employees = 'employees-view',
  LogIn = 'login',
  SignUp = 'signup',
  SignUpPersonal = 'signup-personal',
  SignUpCorporate = 'signup-corporate',
  Plans = 'plans',
  Account = 'account',
  ContactUs = 'contact-us'
}
