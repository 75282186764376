// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-benefits-corporate-tsx": () => import("./../src/pages/benefits-corporate.tsx" /* webpackChunkName: "component---src-pages-benefits-corporate-tsx" */),
  "component---src-pages-benefits-tsx": () => import("./../src/pages/benefits.tsx" /* webpackChunkName: "component---src-pages-benefits-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-how-tsx": () => import("./../src/pages/how.tsx" /* webpackChunkName: "component---src-pages-how-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-philosophy-tsx": () => import("./../src/pages/philosophy.tsx" /* webpackChunkName: "component---src-pages-philosophy-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-toc-tsx": () => import("./../src/pages/toc.tsx" /* webpackChunkName: "component---src-pages-toc-tsx" */)
}

