module.exports = [{
      plugin: require('../../../../../app/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-WBLQTNE076"]},
    },{
      plugin: require('../../../../../app/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"environment":"landing","dsn":"https://9e2124e121f6419fb376f130d6a94fa7@o381818.ingest.sentry.io/5413217"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
