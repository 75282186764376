import React from 'react';

export const SvgWhatsApp = () => (
  <svg width="24" height="24" viewBox="-2 -2 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.1607 4.84024C15.2807 0.400244 9.40071 -0.919756 4.84071 1.84024C0.400712 4.60024 -1.03929 10.6002 1.84071 15.0402L2.08071 15.4002L1.12071 19.0002L4.72071 18.0402L5.08071 18.2802C6.64071 19.1202 8.32071 19.6002 10.0007 19.6002C11.8007 19.6002 13.6007 19.1202 15.1607 18.1602C19.6007 15.2802 20.9207 9.40024 18.1607 4.84024ZM15.6407 14.0802C15.1607 14.8002 14.5607 15.2802 13.7207 15.4002C13.2407 15.4002 12.6407 15.6402 10.2407 14.6802C8.20071 13.7202 6.52071 12.1602 5.32071 10.3602C4.60071 9.52024 4.24071 8.44024 4.12071 7.36024C4.12071 6.40024 4.48071 5.56024 5.08071 4.96024C5.32071 4.72024 5.56071 4.60024 5.80071 4.60024H6.40071C6.64071 4.60024 6.88071 4.60024 7.00071 5.08024C7.24071 5.68024 7.84071 7.12024 7.84071 7.24024C7.96071 7.36024 7.96071 7.60024 7.84071 7.72024C7.96071 7.96025 7.84071 8.20024 7.72071 8.32024C7.60071 8.44024 7.48071 8.68024 7.36071 8.80024C7.12071 8.92024 7.00071 9.16024 7.12071 9.40024C7.60071 10.1202 8.20071 10.8402 8.80071 11.4402C9.52071 12.0402 10.2407 12.5202 11.0807 12.8802C11.3207 13.0002 11.5607 13.0002 11.6807 12.7602C11.8007 12.5202 12.4007 11.9202 12.6407 11.6802C12.8807 11.4402 13.0007 11.4402 13.2407 11.5602L15.1607 12.5202C15.4007 12.6402 15.6407 12.7602 15.7607 12.8802C15.8807 13.2402 15.8807 13.7202 15.6407 14.0802Z"
      fill="black"
    />
  </svg>
);
