import { HorizontalMenu } from '@axmit/clp-library';
import { AppLandingHelper } from '@axmit/router-app-landing';
import Menu from 'antd/lib/menu';
import { COOKIES_NAME, EClientRoutes, ELandingRoutes } from 'const';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React from 'react';
import { useCookies } from 'react-cookie';
import julesMobile from 'static/images/julesMobile.png';
import { languages } from 'locales/i18n';
import { useTranslation } from 'react-i18next';
import { TabsHooks } from 'hooks/Tabs.hooks';
import { getTranslates } from 'helpers/translate.helper';

const HeaderMenu = () => {
  const data = useStaticQuery(query);
  const { i18n } = useTranslation();
  const { language } = i18n;
  const [cookies] = useCookies([COOKIES_NAME]);
  const { activeTabs } = TabsHooks.useSelectedTabs(availableRoutes, '');

  const isAuthorized = !!cookies[COOKIES_NAME];

  return (
    <HorizontalMenu
      mobileImg={julesMobile}
      currentLanguage={language}
      languages={languages}
      selectedKeys={activeTabs}
      updateLanguage={lang => {
        i18n.changeLanguage(lang);
      }}
    >
      <Menu.Item key={ELandingRoutes.Philosophy} onClick={() => navigate(`${ELandingRoutes.Philosophy}`)}>
        {getTranslates(data.allStrapiLesLandCommon, 'header__philosophy', language)}
      </Menu.Item>

      <Menu.Item onClick={() => navigate(`/${ELandingRoutes.How}`)} key={ELandingRoutes.How} className="hidden_desk">
        {getTranslates(data.allStrapiLesLandCommon, 'header__how', language)}
      </Menu.Item>

      <Menu.Item
        onClick={() => navigate(`/${ELandingRoutes.SignInfoCorporate}`)}
        key={ELandingRoutes.SignInfoCorporate}
        className="hidden_desk"
      >
        {getTranslates(data.allStrapiLesLandCommon, 'header__how__corporate', language)}
      </Menu.Item>

      <Menu.Item onClick={() => navigate(`/${ELandingRoutes.SignInfo}`)} key={ELandingRoutes.SignInfo} className="hidden_desk">
        {getTranslates(data.allStrapiLesLandCommon, 'header__how__personal', language)}
      </Menu.Item>

      <Menu.SubMenu
        popupClassName="clp-antd-submenu clp-antd-submenu_xs"
        className="hidden_mob"
        onTitleClick={() => navigate(`/${ELandingRoutes.How}`)}
        title={
          <div className={getSubMenuClass(activeTabs)}>{getTranslates(data.allStrapiLesLandCommon, 'header__how', language)}</div>
        }
      >
        <Menu.Item onClick={() => navigate(`/${ELandingRoutes.SignInfoCorporate}`)} key={ELandingRoutes.SignInfoCorporate}>
          {getTranslates(data.allStrapiLesLandCommon, 'header__how__corporate', language)}
        </Menu.Item>

        <Menu.Item onClick={() => navigate(`/${ELandingRoutes.SignInfo}`)} key={ELandingRoutes.SignInfo}>
          {getTranslates(data.allStrapiLesLandCommon, 'header__how__personal', language)}
        </Menu.Item>
      </Menu.SubMenu>

      <Menu.Item key={ELandingRoutes.FAQ} onClick={() => navigate(`/${ELandingRoutes.FAQ}`)}>
        FAQ
      </Menu.Item>

      {!isAuthorized && (
        <Menu.Item key="signup" onClick={() => AppLandingHelper.toggleRoute(EClientRoutes.SignUp)}>
          {getTranslates(data.allStrapiLesLandCommon, 'header__signup', language)}
        </Menu.Item>
      )}

      <Menu.Item key="login" onClick={() => AppLandingHelper.toggleRoute(EClientRoutes.LogIn)}>
        {cookies[COOKIES_NAME] || getTranslates(data.allStrapiLesLandCommon, 'header__login', language)}
      </Menu.Item>
    </HorizontalMenu>
  );
};

export default HeaderMenu;

const availableRoutes: string[] = [
  ELandingRoutes.How,
  ELandingRoutes.SignInfoCorporate,
  ELandingRoutes.SignInfo,
  ELandingRoutes.Philosophy,
  ELandingRoutes.FAQ
];

const getSubMenuClass = (activeTabs?: string[]): string => {
  if (!activeTabs?.length) {
    return '';
  }

  if (
    activeTabs[0] === ELandingRoutes.How ||
    activeTabs[0] === ELandingRoutes.SignInfoCorporate ||
    activeTabs[0] === ELandingRoutes.SignInfo
  ) {
    return 'ant-menu-item ant-menu-item-selected p-0';
  }

  return '';
};
export const query = graphql`
  query {
    allStrapiLesLandCommon {
      edges {
        node {
          header__login__en
          header__login__fr
          header__philosophy__en
          header__philosophy__fr
          header__how__en
          header__how__fr
          header__how__personal__en
          header__how__personal__fr
          header__how__corporate__en
          header__how__corporate__fr
          header__signup__en
          header__signup__fr
        }
      }
    }
  }
`;
