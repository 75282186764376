import { Container } from '@axmit/clp-library';
import Layout from 'antd/es/layout';
import { Col, Row } from 'antd/es/grid';
import { Link } from 'gatsby';
import React from 'react';
import { withCookies } from 'react-cookie';
import { SvgLogo } from 'components/SVG/SvgLogo';
import HeaderMenu from 'components/HeaderMenu';

const HeaderComponent = () => {
  return (
    <Layout.Header className="clp-header">
      <Container>
        <Row>
          <Col xl={9} lg={11} md={14} xs={21}>
            <Link to="/" className="logo-link">
              <SvgLogo />
            </Link>
          </Col>

          <Col xl={15} lg={13} md={10} xs={3}>
            <HeaderMenu />
          </Col>
        </Row>
      </Container>
    </Layout.Header>
  );
};

export const Header = withCookies(HeaderComponent);
