import { SvgPinterest } from 'components/SVG/SvgPinterest';
import { SvgInstagram } from 'components/SVG/SvgInstagram';
import { SvgWhatsApp } from 'components/SVG/SvgWhatsApp';
import React from 'react';
import { EClientRoutes, ELandingRoutes } from 'const';
import { Footer as ClpFooter } from '@axmit/clp-library';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { AppLandingHelper } from '@axmit/router-app-landing';
import portraitMobile from 'static/images/portraitMob.png';
import portraitDesktop from 'static/images/portraitDesk.png';
import { useTranslation } from 'react-i18next';
import { CLP_LANDING_LINK, INSTAGRAM_LINK, PINTEREST_LINK, WHATSAPP_LINK } from 'const/config';
import { getTranslates } from 'helpers/translate.helper';

const Footer = () => {
  const data = useStaticQuery(query);

  const {
    i18n: { language }
  } = useTranslation();

  return (
    <ClpFooter
      footerImage={portraitDesktop}
      footerImageMobile={portraitMobile}
      socials={[
        <a key="pinterest" href={PINTEREST_LINK} target="_blank" rel="noopener noreferrer">
          <SvgPinterest />
        </a>,
        <a key="Instagram" href={INSTAGRAM_LINK} target="_blank" rel="noopener noreferrer">
          <SvgInstagram />
        </a>,
        <a key="Whatsapp" href={WHATSAPP_LINK} target="_blank" rel="noopener noreferrer">
          <SvgWhatsApp />
        </a>
      ]}
    >
      <nav className="clp-nav">
        <a href={CLP_LANDING_LINK}>Clé Privée</a>
        <Link to={ELandingRoutes.TOC}>{getTranslates(data.allStrapiLesLandCommon, 'footer__tac', language)}</Link>
        <Link to={`/${ELandingRoutes.PrivacyPolicy}`}>
          {getTranslates(data.allStrapiLesLandCommon, 'footer__ppolicy', language)}
        </Link>
        <a onClick={() => AppLandingHelper.toggleRoute(EClientRoutes.ContactUs)}>
          {getTranslates(data.allStrapiLesLandCommon, 'footer__contact', language)}
        </a>
      </nav>
    </ClpFooter>
  );
};

export default Footer;

export const query = graphql`
  query {
    allStrapiLesLandCommon {
      edges {
        node {
          footer__tac__en
          footer__tac__fr
          footer__ppolicy__en
          footer__ppolicy__fr
          footer__contact__en
          footer__contact__fr
        }
      }
    }
  }
`;
