import { useState, useEffect } from 'react';

function useSelectedTabs(availableRoutes: string[], defaultRoute: string) {
  const [activeTabs, setActiveTabs] = useState<Array<string> | undefined>([]);
  const location = typeof window !== 'undefined' ? window?.location.pathname : '';

  useEffect(() => {
    setActiveTabs(getActiveTab(availableRoutes, location, defaultRoute));
  }, [location, availableRoutes, defaultRoute]);

  return { activeTabs };
}

export const TabsHooks = {
  useSelectedTabs
};

const getActiveTab = (availableRoutes: string[], route: string, defaultKey: string): string[] => {
  const currentRoute = availableRoutes.find(item => {
    return route.startsWith(item);
  });

  return currentRoute ? [currentRoute] : [defaultKey];
};
