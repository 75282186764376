interface ITranslationData {
  edges: IEdge[];
}

export interface IEdge {
  node: Record<string, string>;
}

export const getTranslates = (data: ITranslationData, start: string, lng: string): string => {
  return data.edges[0].node[`${start}__${lng}`];
};

export const getEdgeTranslates = (data: IEdge, start: string, lng: string): string => {
  return data.node[`${start}__${lng}`];
};
