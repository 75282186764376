import React from 'react';
import { default as Carousel, CarouselProps } from 'antd/es/carousel';

interface ICarouselItem {
  content: string | JSX.Element;
  sign: string | JSX.Element;
}

interface IComponentProps extends CarouselProps {
  items: ICarouselItem[];
}

export const LandingCarousel = ({ items, className = '', ...allProps }: IComponentProps) => (
  <Carousel {...allProps} className={`landing-carousel ${className}`}>
    {items.map(({ sign, content }, index) => (
      <div key={index}>
        <h3 className="landing-carousel__content">{content}</h3>

        <h3 className="landing-carousel__sign">{sign}</h3>
      </div>
    ))}
  </Carousel>
);
